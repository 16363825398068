* {
    padding: 0;
    margin: 0;
}

.a {
    text-decoration: none;
}

li {
    list-style-position: inside;
}

// th,
// td {
//     word-break: break-all;
// }

// th {
//     white-space: nowrap;
// }

/* make table header sticky */
// thead th {
//     position: sticky;
//     top: 0;
//     background-color: #fff;
// }

// fix in mui autocomplete remove icon
.MuiChip-deleteIcon {
    color: white !important;
}
