// color variants
@import 'themes/themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import 'custom/index.scss';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 6px;
}

//COLORS START
$primary-black: #000;
$primary-purple: #373666;
$grey-white: #dddddd;
$delete-red: rgb(244, 67, 54);
$primary-blue: #2196f3;

//COLORS END

//Icon Colors Start
.icon-tabler-trash {
    color: $delete-red;
}
//Icon Colors End

//Login Page Start
.logo-align {
    padding: 0 100px !important;
    margin-top: 20px;
    margin-bottom: 0;
}

.sign-in-btn {
    background-color: $primary-purple;
    margin-bottom: 20px;
}

.sign-in-forgot {
    color: $primary-purple !important;
}

.sign-in-forgot span {
    color: $primary-purple !important;
}

.login-bg {
    background-color: $grey-white;
}

//Login Page End

//Header Start

.header-logo {
    // background: #fff;
    border-radius: 5px;
    padding: 7px 10px;
}

.nav-menu {
    margin-top: 3px !important;
}

// header{
//     background-color: #006CA6 !important;
// }

//Patient Screen

//UI Max Height
//UI scroll behaviour
#root {
    & > .css-k008qs {
        max-height: 100vh;
        height: 100vh;
    }
}

.patient-title {
    text-decoration: none;
    color: $primary-black;
}

.patient-details {
    margin-left: 7px;
}

.patient-creation-dt {
    display: flex;
    color: $primary-black;
}

.MuiAutocomplete-tag {
    background-color: $primary-blue !important;
    color: #fff !important;
}
// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

// ==============================|| Responsive  ||============================== //

@media only screen and (max-width: 1440px) {
    .css-ngmjm5-MuiCardContent-root {
        padding: 0 !important;
        margin-top: 15px;
    }

    .patient-card-align {
        display: block;
    }

    .patient-card-align .patient-title {
        display: block;
    }

    .patient-card-align .patient-details {
        margin: 0;
    }
}

@media only screen and (max-width: 899px) {
    .nav-menu {
        margin-top: 0;
    }
}

.asyncSelect {
    &__menu {
        z-index: 99 !important;
    }
}

.columnHeaders {
    background-color: #e8f7ff;
    border-bottom: 2px solid #006ca6;
    border-radius: 5px !important;
}

.nestedColumnHeaders {
    background-color: #dddddd;
    th {
        font-size: 16px !important;
    }
}

.columnHeader {
    outline: none !important;
    th {
        font-size: 16px !important;
    }
}

.columnBody {
    p {
        font-size: 16px;
        font-weight: 400;
    }
}

.bordered {
    border: 2px solid #e8f7ff;
}

.validation_failed {
    .MuiOutlinedInput-root {
        border: 1px solid red;
    }

    .MuiFormHelperText-root {
        color: red;
    }

    .MuiFormLabel-root {
        color: red;
        background-color: #fff;
    }
}

.react-grid-item.react-grid-placeholder {
    background: #1b357c !important;
    border-radius: 10px;
}

// .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
//     clip-path: inset(5% 5% 5% 0% round 8px);
// }

/* Scale-in animation */
@keyframes scaleIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.react-grid-layout {
    transition: all 0.5s ease-in-out;
}

.grid-item {
    position: relative;
    border: 1px solid #ccc;
    animation: scaleIn 0.5s forwards;
}

#react-doc-viewer {
    background: transparent;
}

.file_preview_thumb #react-doc-viewer {
    #pdf-controls {
        display: none;
    }
    #proxy-renderer {
        overflow: hidden;
    }
    #rtf-renderer,
    #txt-renderer {
        width: 100%;
        height: 100%;
        margin: 0.4rem;
        overflow: hidden;
        background-color: white;
        padding: 1.3rem;
        border: 1px solid #cecece;
        font-size: 5px;
    }
    #excel-renderer {
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid #cecece;
        padding: 0.4rem;
        overflow: hidden;
        font-size: 5px;
        & > div {
            overflow: hidden;
        }
        tbody > tr > td {
            border-bottom: 1px solid #ededed;
            border-right: 1px solid #ededed;
        }
        tbody > tr:first-child {
            background: #ededed;
        }
    }
    #msdoc-renderer {
        width: 100%;
        height: 100%;
        .docx-wrapper {
            border: 1px solid #ededed;
            padding: 0.4rem;
            background: transparent;
            .docx {
                box-shadow: none;
            }
        }
        #msdoc-canvas > div > section {
            padding: 0.4rem !important;
            height: 100% !important;
            width: 100% !important;
        }
    }
}

.file_fullscreen_preview #react-doc-viewer {
    #pdf-controls {
        margin-right: 2rem;
        background-color: transparent;
        box-shadow: none;
        z-index: 99;
    }
    #pdf-download {
        display: none;
    }
    #header-bar {
        display: none;
    }
    #image-renderer {
        background-color: transparent;
        background-image: none;
    }
    #rtf-renderer,
    #txt-renderer {
        background-color: white;
        padding: 4rem;
        overflow-y: auto;
    }
    #excel-renderer {
        background-color: white;
        padding: 2rem;
        overflow: hidden;
        & > div {
            height: 100%;
            width: 100%;
            overflow: auto;
        }
        tbody > tr > td {
            border-bottom: 1px solid #ededed;
            border-right: 1px solid #ededed;
        }
        tbody > tr:first-child {
            background: #ededed;
        }
    }
    #msdoc-renderer {
        background-color: white;
        padding: 4rem;
        overflow-y: auto;
        .docx-wrapper {
            background: transparent;
            .docx {
                box-shadow: none;
            }
        }
        #msdoc-canvas > div > section {
            padding: 0.4rem !important;
            height: 100% !important;
            width: 100% !important;
        }
    }
}

@media print {
    .invoicing__print__container {
        display: flex;
        height: 100vh;
        width: 100vw;
    }
    .invoicing__print__content {
        flex-grow: 1;
    }
    .invoicing__print__table {
        max-width: 100vw;
    }
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always;
    }
}
@keyframes rotateHorizontal {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
