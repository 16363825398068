@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import 'variables';

@media print {
    @page {
        size: portrait;
    }
}

.print-only {
    display: none;

    @media print {
        display: block;
    }
}

.no-print {
    @media print {
        display: none;
    }
}

// esign styles
.sign-modal {
    @media print {
        display: none;
    }
}

.sign-info {
    margin: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin-right: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #616161;
    }
}

.print-pdf {
    padding: 0;
    color: $textColor;
    line-height: 1.4;

    @media print {
        padding: 40px;

        ::-webkit-scrollbar {
            display: none;
        }
    }

    * {
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 16px;
        // color: #424242;
    }

    // pdf header
    .pdf-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .print-logo {
            width: 100px;
            height: 80px;
            object-fit: contain;
        }

        .title {
            font-size: 24px;
            font-weight: 600;
            color: #212121;
        }
    }

    .table-container {
        max-width: 100%;
        // overflow-x: auto;
    }

    table {
        margin-bottom: 24px;
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            padding: 8px 12px;
            border: 1px solid #ccc;
            color: #424242;
        }

        th {
            text-align: left;
            font-weight: 600;
            font-size: 16px;
        }
        td {
            font-weight: 400;
            font-size: 15px;
        }
    }

    input {
        background-color: inherit;
        border: none;
        font-size: 18px;
        margin-left: 16px;
    }

    // *[contenteditable],
    textarea,
    .textarea {
        font-family: 'Noto Sans', sans-serif !important;
        margin-bottom: 16px !important;
        // color: #616161;
        border: none !important;
        resize: none;

        &:disabled {
            background-color: inherit;
        }
    }

    p[contenteditable],
    div[contenteditable],
    textarea {
        font-family: 'Noto Sans', sans-serif !important;
        margin-bottom: 16px !important;
        width: 100%;
    }

    span[contenteditable] {
        font-family: 'Noto Sans', sans-serif !important;
        display: inline-block;
        margin: 0 16px;
    }

    hr {
        margin-bottom: 24px;
        border: #e2e1e1 1px solid;
    }

    label,
    .label {
        // display: inline-block;
        display: flex;
        margin-bottom: 8px;
        color: #212121;
        font-weight: 600;
        font-size: 15px;
    }

    h2 {
        margin-bottom: 8px;
        color: #212121;
        font-weight: 600;
        font-size: 16px;
    }

    h3 {
        margin-bottom: 4px;
        color: #212121;
        font-weight: 500;
        font-size: 16px;
    }

    // evaluation styles
    #adl-table {
        overflow: auto;
    }

    .therapy-eob {
        margin-bottom: 8px;
        font-size: 15px;
        color: #212121;

        input {
            color: #212121;
        }
    }

    .plan-duration {
        margin: 24px 0;
    }

    .plan-table {
        margin: 24px 0;
    }

    // treatment styles
    .plan-box {
        margin-bottom: 16px;

        h2 {
            margin-bottom: 8px;
        }
        p {
            white-space: pre-wrap;
        }
    }
}

// itp
.itp-print {
    .pdf-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .print-logo {
            width: 100px;
            height: 80px;
            object-fit: contain;
        }

        .title {
            font-size: 24px;
            font-weight: 600;
            color: #212121;
        }
    }

    table {
        border-collapse: collapse;

        th,
        td {
            border: 1px solid #ccc;
        }
    }
}
