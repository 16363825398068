.dms-form {
    @media print {
        padding: 40px 0;
    }
    /*******dmsmodalcss********/
    .clear {
        clear: both;
    }
    .dmsModal .modal-lg {
        width: 1250px;
    }
    .dmsresult-container {
        width: 1200px;
        margin: 0 auto;
        height: 1510px;
        position: relative;
    }
    .logodiv {
        text-align: center;
    }
    .addinfo {
        margin-bottom: 40px;
        width: 240px;
        // float: right;
        position: absolute;
        right: 24px;
        z-index: 6;
        top: 0;
    }
    .dmsmaincontent {
        margin-top: 40px;
        position: relative;
    }
    .dmsimgcont {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 3;
    }
    .checkbox-referral {
        position: absolute;
        width: 100%;
        top: 336px;
        left: 0;
        z-index: 3;
    }
    input[type='checkbox'].otcheckbox {
        position: absolute;
        left: 529.8px;
        top: 3px;
        z-index: 3;
    }
    input[type='checkbox'].ptcheckbox {
        position: absolute;
        left: 600.8px;
        top: 3px;
        z-index: 3;
    }
    input[type='checkbox'].stcheckbox {
        position: absolute;
        left: 669.8px;
        top: 3px;
        z-index: 3;
    }
    input[type='checkbox'].treatcheckbox {
        position: absolute;
        left: 848.8px;
        top: 3px;

        z-index: 3;
    }
    .dtcheckbox {
        width: auto;
        position: absolute;
        left: 748.8px;
        top: 5px;
        z-index: 3;
    }
    .ptinfo {
        // width: 830px;
        position: absolute;
        top: 96px;
        left: 130px;
        z-index: 100;
    }
    .ptlastseen {
        position: absolute;
        top: 138px;
        left: 140px;
        z-index: 100;
        // width: 810px;
    }
    .prescribed {
        position: absolute;
        top: 182px;
        left: 140px;
        z-index: 100;
        // width: 800px;
    }
    .input-therapy1 {
        position: absolute;
        width: 800px;
        left: 132px;
        top: 330px;
        z-index: 3;
    }
    .input-therapy2 {
        position: absolute;
        width: 800px;
        left: 132px;
        top: 357px;
        z-index: 3;
    }
    .inputcheck {
        position: absolute;
        left: 131px;
        top: 385px;
        width: 800px;
    }
    .other-info {
        position: absolute;
        top: 438px;
        left: 150px;
        z-index: 100;
        width: 920px;
    }
    .other-info2 {
        position: absolute;
        top: 465px;
        left: 52px;
        z-index: 100;
        width: 800px;
    }
    .pcp-name {
        position: absolute;
        top: 697px;
        left: 128px;
        z-index: 100;
        width: 490px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .provd-npi {
        position: absolute;
        top: 694px;
        left: 662px;
        z-index: 100;
        width: 150px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .provd-taxonomy {
        position: absolute;
        top: 695px;
        left: 850px;
        z-index: 100;
        width: 150px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .attending-pcpname {
        position: absolute;
        top: 764px;
        left: 128px;
        z-index: 100;
        width: 400px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .provd-npi2 {
        position: absolute;
        top: 762px;
        left: 660px;
        z-index: 100;
        width: 150px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .provd-taxonomy2 {
        position: absolute;
        top: 762px;
        left: 850px;
        z-index: 100;
        width: 150px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .pcp-signature {
        position: absolute;
        top: 946px;
        left: 128px;
        z-index: 100;
        width: 400px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .dms-date {
        position: absolute;
        top: 946px;
        left: 662px;
        z-index: 100;
        width: 300px;
        font-size: 1.2em;
        background-color: transparent;
    }
    .dms-provdname {
        position: absolute;
        top: 1014px;
        left: 386px;
        z-index: 100;
        width: 550px;
        font-size: 18px;
    }

    /**********end dms modal********/

    input,
    *[contenteditable='true'] {
        border: none;
        outline: none;
    }

    input[type='checkbox'] {
        width: 24px;
        height: 24px;
    }

    input[type='text'],
    *[contenteditable='true'] {
        height: 24px;
    }
}
