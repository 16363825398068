:root {
    --white: #ffffff;
    --green: rgb(40, 167, 69);
    --red: rgb(27, 53, 124);
    --yellow: rgb(255, 193, 7);
    --blue: rgb(0, 123, 255);
    --orange: rgb(253, 126, 20);
    --gray: rgb(108, 117, 125);
    --teal: rgb(0, 128, 128);
}
.r-title {
    margin-top: var(--rTitleMarginTop, 0) !important;
    margin-bottom: var(--rTitleMarginBottom, 0) !important;
}

p:not([class]) {
    line-height: var(--cssTypographyLineHeight, 1.78);
    /* margin-top: var(--cssTypographyBasicMargin, 1em); */
    margin-bottom: 0;
}

p:not([class]):first-child {
    margin-top: 0;
}

/*
text component
*/

.text {
    display: var(--textDisplay, inline-flex);
    font-size: var(--textFontSize, 1rem);
}

/*
time component
*/

/*
core styles
*/

.time {
    display: var(--timeDisplay, inline-flex);
}

/*
extensions
*/

.time__month {
    margin-left: var(--timelineMounthMarginLeft, 0.25em);
}

/*
skin
*/

.time {
    padding: var(--timePadding, 0.25rem 1.25rem 0.25rem);
    background-color: var(--timeBackgroundColor, #f0f0f0);

    font-size: var(--timeFontSize, 0.75rem);
    font-weight: var(--timeFontWeight, 700);
    text-transform: var(--timeTextTransform, uppercase);
    color: var(--timeColor, currentColor);
}

/*
card component
*/

/*
core styles
*/

.card {
    padding: var(--timelineCardPadding, 1.5rem 1.5rem 1.25rem);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.card__content {
    font-weight: 500;
    margin-top: var(--cardContentMarginTop, 1rem);
}

.card__content span {
    color: #a4a4a4;
}

/*
skin
*/

.card {
    border-radius: var(--timelineCardBorderRadius, 2px);
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--timelineCardBorderLeftColor, var(--uiTimelineMainColor));
    box-shadow: var(--timelineCardBoxShadow, 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24));
    background-color: var(--timelineCardBackgroundColor, #fff);
}

/*
extensions
*/

.card__title {
    --rTitleMarginTop: var(--cardTitleMarginTop, 1rem);
    font-size: var(--cardTitleFontSize, 1.25rem);
}

/*
=====
CORE STYLES
=====
*/

.timeline {
    display: var(--timelineDisplay, grid);
    grid-row-gap: var(--timelineGroupsGap, 2rem);
}

/*
1. If timeline__year isn't displaed the gap between it and timeline__cards isn't displayed too
*/

.timeline__year {
    margin-bottom: 1.25rem; /* 1 */
}

.timeline__cards {
    display: var(--timeloneCardsDisplay, grid);
    grid-row-gap: var(--timeloneCardsGap, 1.5rem);
}

/*
=====
SKIN
=====
*/

.timeline {
    --uiTimelineMainColor: var(--timelineMainColor, #222);
    --uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);

    border-left: var(--timelineLineWidth, 3px) solid var(--timelineLineBackgroundColor, var(--uiTimelineMainColor));
    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

.timeline__year {
    --timePadding: var(--timelineYearPadding, 0.5rem 1.5rem);
    --timeColor: var(--uiTimelineSecondaryColor);
    --timeBackgroundColor: var(--uiTimelineMainColor);
    --timeFontWeight: var(--timelineYearFontWeight, 400);
}

.timeline__card {
    position: relative;
    margin-left: var(--timelineCardLineGap, 1rem);
}

/*
1. Stoping cut box shadow
*/

.timeline__cards {
    overflow: hidden;
    padding-top: 0.25rem; /* 1 */
    padding-bottom: 0.25rem; /* 1 */
}

.timeline__card::before {
    content: '';
    width: 50%;
    height: var(--timelineCardLineWidth, 2px);
    background-color: var(--timelineCardLineBackgroundColor, var(--uiTimelineMainColor));

    position: absolute;
    top: var(--timelineCardLineTop, 1rem);
    left: -50%;
    z-index: -1;
}

.timeline {
    --timelineMainColor: #1b357c;
}

.page {
    background-color: #f2f2f2;
    border-radius: 10px;
    max-width: 70rem;
    max-height: 100vh;
    padding: 3rem;
    overflow-y: scroll;
    margin-right: auto;
}

.substack {
    border: 1px solid #eee;
    background-color: #fff;
    width: 100%;
    max-width: 480px;
    height: 280px;
    margin: 1rem auto;
}

.linktr {
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    text-align: center;
}

.linktr__goal {
    background-color: rgb(209, 246, 255);
    color: rgb(8, 49, 112);
    box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
    border-radius: 2rem;
    padding: 0.75rem 1.5rem;
}

.r-link {
    --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
    --uirLinkTextColor: var(--rLinkTextColor);
    --uirLinkTextDecoration: var(--rLinkTextDecoration, none);

    display: var(--uirLinkDisplay) !important;
    color: var(--uirLinkTextColor) !important;
    text-decoration: var(--uirLinkTextDecoration) !important;
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.card:nth-child(2) {
    animation-delay: calc(2 * 0.05s);
}

.card:nth-child(3) {
    animation-delay: calc(3 * 0.05s);
}

.card:nth-child(4) {
    animation-delay: calc(4 * 0.05s);
}

.card:nth-child(5) {
    animation-delay: calc(5 * 0.05s);
}

.card:nth-child(6) {
    animation-delay: calc(6 * 0.05s);
}

.card:nth-child(7) {
    animation-delay: calc(7 * 0.05s);
}

.card:nth-child(8) {
    animation-delay: calc(8 * 0.05s);
}

.card:nth-child(9) {
    animation-delay: calc(9 * 0.05s);
}

.card:nth-child(10) {
    animation-delay: calc(10 * 0.05s);
}

.card:nth-child(11) {
    animation-delay: calc(11 * 0.05s);
}

.card:nth-child(12) {
    animation-delay: calc(12 * 0.05s);
}

.card:nth-child(13) {
    animation-delay: calc(13 * 0.05s);
}

.card:nth-child(14) {
    animation-delay: calc(14 * 0.05s);
}

.card:nth-child(15) {
    animation-delay: calc(15 * 0.05s);
}

.card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeline__action {
    padding: 5px;
    font-size: var(--timeFontSize, 0.75rem);
    font-weight: var(--timeFontWeight, 700);
    text-transform: var(--timeTextTransform, uppercase);
    color: var(--timeColor, currentColor);
}

.timeline__create {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--green);
    .timeline__action {
        background-color: var(--green);
        color: var(--white);
    }
}

.timeline__delete {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--red);
    .timeline__action {
        background-color: var(--red);
        color: var(--white);
    }
}

.timeline__update {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--yellow);
    .timeline__action {
        background-color: var(--yellow);
        color: var(--white);
    }
}

.timeline__general {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--blue);
    .timeline__action {
        background-color: var(--blue);
        color: var(--white);
    }
}

.timeline__activate {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--orange);
    .timeline__action {
        background-color: var(--orange);
        color: var(--white);
    }
}

.timeline__deactivate {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--gray);
    .timeline__action {
        background-color: var(--gray);
        color: var(--white);
    }
}

/* session */
.timeline__externadd {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--green);
    .timeline__action {
        background-color: var(--green);
        color: var(--white);
    }
}

.timeline__unlock {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--orange);
    .timeline__action {
        background-color: var(--orange);
        color: var(--white);
    }
}

.timeline__lock {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--gray);
    .timeline__action {
        background-color: var(--gray);
        color: var(--white);
    }
}

.timeline__checkin {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--orange);
    .timeline__action {
        background-color: var(--orange);
        color: var(--white);
    }
}

.timeline__checkout {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--gray);
    .timeline__action {
        background-color: var(--gray);
        color: var(--white);
    }
}

.timeline__therapyin {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--orange);
    .timeline__action {
        background-color: var(--orange);
        color: var(--white);
    }
}

.timeline__therapyout {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--gray);
    .timeline__action {
        background-color: var(--gray);
        color: var(--white);
    }
}

.timeline__present {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--orange);
    .timeline__action {
        background-color: var(--orange);
        color: var(--white);
    }
}

.timeline__absent {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--gray);
    .timeline__action {
        background-color: var(--gray);
        color: var(--white);
    }
}

.timeline__continuesession {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--orange);
    .timeline__action {
        background-color: var(--orange);
        color: var(--white);
    }
}

.timeline__sign {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--gray);
    .timeline__action {
        background-color: var(--gray);
        color: var(--white);
    }
}

.timeline__download {
    border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--teal);
    .timeline__action {
        background-color: var(--teal);
        color: var(--white);
    }
}
