
  
  .wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button {





    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
    /* transition: all 0.3s ease 0s; */
    transition: transform .2s;
    cursor: pointer;
    width: min-content;
    height: min-content;
    outline: none;
    }
  
  .button:hover {
    background-color: none;
    /* box-shadow: 2px 2px 2px 2px #6167d8a2; */
    color: #fff;
    transform: scale(1.1);
  }