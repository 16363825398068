@import 'reset';
@import 'layout';
@import 'common';
@import 'dms-form';

@import 'template';
@import 'print';

// pandadoc
// div[step='sign_completed_by_actor'],
// div[step='completed_step'] {
//     display: none;
// }

.prescription #pdf-controls {
    display: none !important;
}

.pandadoc-cotainer {
    /*   width: 100%;
      height: 100%; */
    position: relative;
}

.pandadoc-button-overlay {
    display: inline-block;
    width: 160px;
    height: 60px;
    background-color: white;

    position: absolute;
    bottom: 8px;
    right: 8px;
}

// schedule
.fc a {
    color: #212121;
}

.fc-list-event {
    cursor: pointer;
}

.fc-h-event .fc-event-time {
    overflow: unset !important;
}

.fc-popover-body {
    max-height: 150px !important;
    overflow: scroll !important;
}

// billing details
.billing-info {
    .billing-info-vertical-tab {
        text-align: left;
        margin-bottom: 8px;
        background-color: inherit;
        cursor: pointer;
        width: 100%;
        padding: 10px;
        padding-left: 16px;
        border: none;
    }

    .no-data {
        text-align: left;
    }
}

.billing-info .MuiButtonBase-root {
    text-align: left !important;
}

.txt-cntr > * > * {
    text-align: center;
}
