.no-data {
    text-align: center;
    font-size: 14px;
    color: #9e9e9e;
    margin: 24px 0;
}

.pagination {
    margin: 24px 16px;
    display: flex;
    justify-content: center;
}

.view-modal-content {
    ul {
        margin-top: 24px;
    }
}

.fc .fc-popover {
    z-index: 999 !important;
}

.title-case {
    text-transform: capitalize;
}

// auto complete
.aspyre-auto-complete {
    // border: 1px solid #bdbdbd;
    // border-radius: 8px;

    .MuiInputBase-root {
        position: relative;
        max-height: 100px !important;
        overflow: scroll;
        display: flex;
        align-items: center;

        // .MuiOutlinedInput-notchedOutline {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     width: 100%;
        //     height: 100%;
        // }
    }
}
// calendar overflow fix
// .MuiPickersLayout-root {
//     max-height: 400px;
//     overflow: scroll;
// }

// pdf preview

// .react-pdf__Page__canvas {
//     margin: 0 auto;
//     width: 80% !important;
//     height: 100% !important;
// }
