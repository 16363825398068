@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

// .noto-sans-<uniquifier> {
//     font-family: "Noto Sans", sans-serif;
//     font-optical-sizing: auto;
//     font-weight: <weight>;
//     font-style: normal;
//     font-variation-settings:
//       "wdth" 100;
//   }

.templates {
    line-height: 1.4;

    .input-container {
        position: relative;
    }

    // label {
    //     display: inline-block;
    //     margin-bottom: 8px;
    //     color: black;
    //     font-weight: 500;
    //     font-size: 14px;
    // }

    .table-container {
        max-width: 100%;
        overflow-x: auto;
    }

    // table {
    //     margin-bottom: 24px;
    //     width: 100%;
    //     border-collapse: collapse;
    // }

    // th,
    // td {
    //     word-break: break-all;
    // }

    // th {
    //     padding: 8px;
    //     // background-color: #bdbdbd;
    //     border: 1px solid #ccc;
    // }

    // td {
    //     background-color: unset !important;
    //     padding: 8px;
    //     border: 1px solid #ccc;
    //     white-space: normal;
    // }

    *[contenteditable] {
        // background-color: white;
        // border: 1px solid #bdbdbd;
        // font-size: 16px;
        // border-radius: 5px;
        font-family: 'Noto Sans', sans-serif !important;
        padding: 4px;
        background-clip: content-box;
        background-color: #e3f2fd;

        &:hover {
            outline-color: #1b357c;
        }
    }

    p[contenteditable],
    div[contenteditable],
    textarea {
        font-family: 'Noto Sans', sans-serif !important;
        margin-bottom: 16px;
        margin-right: 16px;
        width: 100%;
        min-height: 160px;
        resize: none;
        // background-color: white;
        // border: 1px solid #bdbdbd;
        // font-family: 'Roboto', sans-serif;
        // font-size: 16px;
        // padding: 10px;
        // border-radius: 10px;
    }

    span[contenteditable] {
        font-family: 'Noto Sans', sans-serif !important;
        display: inline-block;
        margin: 0 16px;
        min-width: 100px !important;
        height: 40px;
        // background-color: white;
        // border: 1px solid #bdbdbd;
        // font-family: 'Roboto', sans-serif;
        // font-size: 16px;
        // padding: 10px;
        // border-radius: 5px;
    }

    hr {
        margin-bottom: 24px;
        border: #e2e1e1 1px solid;
    }
}

// general analysis
.general-analysis {
    .analysis-checkbox {
        margin: 0;

        * {
            margin: 0;
            font-size: 22px;
        }
    }

    label {
        // display: inline-block;
        margin-bottom: 8px;
        color: black;
        font-weight: 500;
        font-size: 14px;
    }

    textarea {
        margin-bottom: 16px;
        border-width: 1px;
        border-color: #bdbdbd;

        &:focus {
            outline-color: #1b357c;
            border-color: #1b357c;
            border-width: 2px;
            box-shadow: unset;
        }
    }

    #adl-table {
        overflow: auto;
    }

    input:disabled,
    textarea:disabled {
        opacity: 0.5;
        color: transparent;
        cursor: not-allowed;
        user-select: none;

        &::selection {
            background: transparent;
        }
    }
}
